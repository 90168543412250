import React from "react";
import {Article} from "../../scenes/articles/ArticleModels";
import {
    createArticleApi,
    CreateArticleCommand,
    deleteArticleApi,
    DeleteArticleCommand, importKeywordSheet,
    listAllArticlesApi,
    updateArticleApi,
    UpdateArticleCommand
} from "../../scenes/articles/WritingTaskApi";
import {useAuth} from "../../providers/AuthProvider";
import {Dictionary, keyBy} from "lodash";
import {UpdateTimestampDao, useUpdateTimestampDao} from "../../content/dao/UpdateTimestampDao";
import {useWritingTaskDao, WritingTaskDao} from "./WritingTaskDao";

const ArticleStoreContext = React.createContext<ArticleStoreContextType>(null!);
const CHECK_ARTICLES_INTERVAL_IN_MS = 60 * 1000;

type State = {
    articlesById: Dictionary<Article>,
    snapshotTime: number,
}

export function ArticleStoreProvider({ children }: { children: React.ReactNode }) {
    const auth               = useAuth()
    const writingTaskDao     = useWritingTaskDao()
    const updateTimestampDao = useUpdateTimestampDao()
    const [state, setState]  = React.useState<State>()

    React.useEffect(() => {
        if (auth.tokens) {
            if (state === undefined) {
                initializeState(auth.accessTokenPromise, writingTaskDao, updateTimestampDao, setState)
            } else {
                const timeout = setTimeout(() => {
                    updateState(auth.accessTokenPromise, writingTaskDao, updateTimestampDao, state, setState)
                }, CHECK_ARTICLES_INTERVAL_IN_MS);
                return () => { clearTimeout(timeout) };
            }
        }
    }, [auth.tokens, auth.accessTokenPromise, state, setState, writingTaskDao, updateTimestampDao])

    const updateArticle = async (updateCommand: UpdateArticleCommand) => {
        const accessToken    = await auth.accessTokenPromise
        const updatedArticle = await updateArticleApi(accessToken, updateCommand)

        await updateLocalState(updatedArticle)
    };

    const updateLocalState = async (updatedWritingTask: Article) => {
        const newWritingTasksById = { ...state?.articlesById }
        newWritingTasksById[updatedWritingTask.taskId] = updatedWritingTask

        // we don't set snapshot time, as it's possible some other thread
        // modified something between
        setState({
            ...state!,
            articlesById: newWritingTasksById,
        })
    }

    // this shouldn't really delete the stuff
    const deleteArticle = async (deleteCommand: DeleteArticleCommand) => {
        const accessToken    = await auth.accessTokenPromise
        const deletedArticle = await deleteArticleApi(accessToken, deleteCommand)

        const articlesById = ({ ...state?.articlesById })

        delete articlesById[deletedArticle.taskId]

        // we don't set snapshot time, as it's possible some other thread
        // modified something between
        setState({
            ...state!,
            articlesById,
        })
    };

    const createArticle = async (createCommand: CreateArticleCommand) => {
        const accessToken    = await auth.accessTokenPromise
        const createdArticle = await createArticleApi(accessToken, createCommand)

        await updateLocalState(createdArticle)
    };

    const importArticles = async (url: string) => {
        const accessToken = await auth.accessTokenPromise

        await importKeywordSheet(accessToken, url)
    };

    const contextValue = {
        articlesById: state?.articlesById || {},
        updateArticle,
        deleteArticle,
        createArticle,
        importArticles,
    };

    return <ArticleStoreContext.Provider value={contextValue}>{children}</ArticleStoreContext.Provider>;
}

export function useArticleStore() {
    return React.useContext(ArticleStoreContext);
}

interface ArticleStoreContextType {
    articlesById:  Dictionary<Article>,
    updateArticle: (_: UpdateArticleCommand) => Promise<void>,
    deleteArticle: (_: DeleteArticleCommand) => Promise<void>,
    createArticle: (_: CreateArticleCommand) => Promise<void>,
    importArticles: (_: string)              => Promise<void>,
}

async function initializeState(
  accessTokenPromise: Promise<string | undefined>,
  writingTaskDao:     WritingTaskDao,
  updateTimestampDao: UpdateTimestampDao,
  setState:           (state: State) => void,
) {

    console.debug("Initializing content store")
    const writingTasksInDb = await writingTaskDao.getAll()
    const lastUpdated      = await updateTimestampDao.get("writing-tasks")

    const accessToken = await accessTokenPromise
    const writingTasksFromServer = await listAllArticlesApi(accessToken, lastUpdated)

    const writingTasksInDbById = keyBy(writingTasksInDb, task => task.taskId)
    const snapshotTime = writingTasksFromServer.snapshotTime

    await updateTimestampDao.put("writing-tasks", snapshotTime)
    if (writingTasksFromServer.writingTasks.length > 0) {
        const writingTasksFromServerById = keyBy(writingTasksFromServer.writingTasks, task => task.taskId)
        await writingTaskDao.putAll(writingTasksFromServer.writingTasks)

        const articlesById = { ...writingTasksInDbById, ...writingTasksFromServerById }

        setState({
            articlesById,
            snapshotTime,
        })
    } else {
        const articlesById = writingTasksInDbById

        setState({
            articlesById,
            snapshotTime,
        })
    }
}

async function updateState(
  accessTokenPromise: Promise<string | undefined>,
  writingTaskDao:     WritingTaskDao,
  updateTimestampDao: UpdateTimestampDao,
  state:              State,
  setState:           (state: State) => void,
) {
    const accessToken            = await accessTokenPromise
    const writingTasksFromServer = await listAllArticlesApi(accessToken, state.snapshotTime)

    const snapshotTime = writingTasksFromServer.snapshotTime

    await updateTimestampDao.put("writing-tasks", snapshotTime)

    if (writingTasksFromServer.writingTasks.length > 0) {
        const contentsFromServerById = keyBy(writingTasksFromServer.writingTasks, task => task.taskId)
        await writingTaskDao.putAll(writingTasksFromServer.writingTasks)
        const articlesById = { ...(state.articlesById), ...contentsFromServerById }

        setState({
            articlesById,
            snapshotTime,
        })
    } else {

        setState({
            ...state,
            snapshotTime,
        })
    }
}
