import DoneIcon from '@mui/icons-material/Done';
import {
  Badge,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import React, {ReactElement} from "react";
import {Link, useLocation} from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import {useAuth} from '../providers/AuthProvider';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {postAllBuild} from "../apis/Apis";

export function SideBar() {
  const { logout } = useAuth();

  const logoutButtonHandler = () => {
    logout(() => {}, () => {});
  };

  return (
    <Drawer
      anchor='left'
      variant="permanent"
      PaperProps={{ elevation: 0, sx: {width: "13em"} }}
      sx={{ width: "13em" }}
    >
      <Stack direction="column" justifyContent="space-between" sx={{
        pt: '1em',
        overflow: 'auto',
        height: '100%',
      }}>
        <List dense>
          <ArticleWriting/>
          <MyDivider/>
          <ContentsItem/>
          <MyDivider/>
          <BuildsItem/>
          <MyDivider/>
          <ImagesItem/>
          <MyDivider/>
        </List>
        <Box sx={{
          padding: "1em",
        }}>
          <Button fullWidth variant='outlined' size='large' color='secondary' onClick={logoutButtonHandler}>Logout</Button>
        </Box>
        
      </Stack>
    </Drawer>
  );
  
  function ArticleWriting() {
    return <>
      <MyListSubheader title="Articles"/>
      <MyListItem key="articles" to="/articles/all" label="Articles" icon={<EditNoteIcon/>}/>
    </>;
  }
  
  function ContentsItem() {
    return <>
      <MyListSubheader title="Contents"/>
      <MyListItem key="all" to="/contents/lists/all"    label="All" icon={<DoneIcon/>}/>
      {/*<MyListItem key="all" to="/contents/lists/drafts" label="Drafts" icon={<DrawIcon/>}/>*/}
    </>;
  }

  function BuildsItem() {
    return <>
      <MyListSubheader title="Builds"/>
      <MyListItem key="builds" to="/builds" label="Builds" icon={<PlayArrowIcon/>}/>
      <MyListItem2 key="images" label="Trigger All Build" icon={<PlayArrowIcon/>}/>
    </>;
  }

  function ImagesItem() {
    return <>
      <MyListSubheader title="Images"/>
      <MyListItem key="images" to="/images" label="All Images" icon={<PhotoLibraryIcon/>}/>
    </>;
  }

  function MyListSubheader(props: {title: string}) {
    return (
      <ListSubheader>
        <Typography variant="body2">{props.title}</Typography>
      </ListSubheader>
    );
  }

  function MyListItem(props: {key: string, to: string, label: String, icon: ReactElement, warnings?: number, disabled?: boolean}) {
    const location = useLocation();
    return (
      <ListItemButton component={Link} to={props.to} selected={location.pathname.startsWith(props.to)} disabled={props.disabled}>
        <ListItemIcon>{React.cloneElement(props.icon, {  fontSize: "small", variant: "default" })}</ListItemIcon>
        {
          props.warnings === undefined 
            ? <ListItemText primary={props.label}></ListItemText>
            : <Badge badgeContent={props.warnings} color="warning">
                <ListItemText primary={props.label}></ListItemText>
              </Badge>
        }
      </ListItemButton>
    );
  }

  function MyListItem2(props: {label: String, icon: ReactElement, disabled?: boolean}) {
    return (
      <ListItem>
        <Button onClick={() => postAllBuild() } fullWidth variant="outlined">{props.label}</Button>
      </ListItem>
    );
  }

  function MyDivider() {
    return <Divider sx={{mt: "0.5em", mb: "0.5em"}}/>;
  }
}