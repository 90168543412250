namespace Models {

  export type UpdateContentCommand = {
    authorId?        : string
    categories?      : ReadonlyArray<string>
    contentId        : string
    directory?       : string
    excerpt?         : string
    featuredImageId? : string
    internalTags?    : ReadonlyArray<string>
    isAiArticle?     : boolean
    isFeatured?      : boolean
    isSponsored?     : boolean
    jsonLd1?         : string
    markdownString?  : string
    metaTitle?       : string
    metaDescription? : string
    notes?           : string
    slug?            : string
    typ3?            : string
    websiteName?     : string
  }

  export type PublishContentCommand = {
    contentId          : string
    customPublishTime? : number
  }

  export type CreateAiDescriptionCommand = {
    contentId          : string
  }

  export type UnpublishContentCommand = {
    contentId : string
  }

  export type ArchiveContentCommand = {
    contentId : string
  }

  export type UnarchiveContentCommand = {
    contentId : string
  }

  export type ContentListResponse = {
    contents:       ReadonlyArray<ContentState>
    snapshotTime:   number
    lastContentId?: string
  }

  export type ContentState = {
    authorId                   : string
    categories                 : ReadonlyArray<string>
    directory                  : string
    documentTitle              : string
    excerpt                    : string
    featuredImageId?           : string
    id                         : string
    internalTags?              : ReadonlyArray<string>
    isFeatured                 : boolean
    isSponsored                : boolean
    isAiArticle?               : boolean
    isArchived?                : boolean
    jsonLd1?                   : string
    lastUpdated                : number
    markdownString             : string
    metaTitle?                 : string
    metaDescription?           : string
    notes?                     : string
    publishTime?               : number
    slug                       : string
    typ3                       : string
    websiteName                : string
    blackListedDomainWarnings? : ReadonlyArray<string>
  }
}

export default Models