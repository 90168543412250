import {useParams} from "react-router-dom";
import {Page} from "../../../components/Page";
import {InitializedContentStoreContext, useContentStore} from "../../ContentStoreProvider";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography
} from "@mui/material";

import {DateTimePicker, DateTimeValidationError, PickerChangeHandlerContext} from "@mui/x-date-pickers";
import {KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import React from "react";
import dayjs, {Dayjs} from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import PublishIcon from "@mui/icons-material/Publish";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import CancelIcon from "@mui/icons-material/Cancel";
import {PublishDialog} from "./components/PublishDialog";
import Models from "../../api/Models";
import {isEqualIgnoreUndefined} from "../../../support/Utils";
import {UnpublishDialog} from "./components/UnpublishDialog";
import {useSnackbar} from "../../../common/SnackbarProvider";
import {MarkdownEditor} from "../../../common/MarkdownEditor";
import {ArchiveDialog} from "./components/ArchiveDialog";
import {UnarchiveDialog} from "./components/UnarchiveDialog";
import UpdateContentCommand = Models.UpdateContentCommand;
import Alert from "@mui/material/Alert";

type MutateFn<T> = (t: T | undefined) => Partial<Models.ContentState>
type HtmlInputChangeHandler<T> = (event: React.ChangeEvent<T>) => void
type DateTimeChangeHandler = (dayjs: Dayjs | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => void

type ContentEditPageState = {
  isAnythingChanged:     boolean
  currentForm:           Partial<Models.ContentState>
  original:              Models.ContentState
  handleBooleanChange:   (mutate: MutateFn<boolean>)               => HtmlInputChangeHandler<HTMLInputElement>
  handleStringChange:    (mutate: MutateFn<string>)                => HtmlInputChangeHandler<HTMLInputElement | HTMLTextAreaElement>
  handleStringChange2:   (mutate: MutateFn<string>)                => (newValue: string) => void
  handleStringSetChange: (mutate: MutateFn<ReadonlyArray<string>>) => HtmlInputChangeHandler<HTMLInputElement>
  handleInstantChange:   (mutate: MutateFn<number>)                => DateTimeChangeHandler
}

export function ContentEditPage() {
  const { contentId } = useParams();
  const contentStore  = useContentStore();

  return (
    <Page title="Edit">
      { contentStore.isInitialized
        ? <ContentEditComponent contentId={contentId!} contentStore={contentStore}/>
        : <LoadingEditComponent/>
      }
    </Page>
  )
}

function LoadingEditComponent() {
  return (
    <Stack spacing={2} direction="row">
      <Box width="100%">
        <Skeleton variant="rounded" height="50em"/>
      </Box>
      <Box width="512px">
        <Skeleton variant="rounded" height="50em" width="512px"/>
      </Box>
    </Stack>
  )
}

function ContentEditComponent(props: {contentId: string, contentStore: InitializedContentStoreContext}) {
    const content = props.contentStore.contentsById[props.contentId];

    const [isAnythingChanged, setIsAnythingChanged] = React.useState(false);
    const [contentForm,       setContentForm]       = React.useState<Partial<Models.ContentState>>(content!);

    const handleStringChange = React.useCallback((mutate: MutateFn<string>) => {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const str = event.target.value.length === 0 ? undefined : event.target.value
        const newForm = mutate(str)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleStringChange2 = React.useCallback((mutate: MutateFn<string>) => {
      return (newValue: string) => {
        const str = newValue.length === 0 ? undefined : newValue
        const newForm = mutate(str)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleStringSetChange = React.useCallback((mutate: MutateFn<ReadonlyArray<string>>) => {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const str = event.target.value.length === 0 ? undefined : event.target.value
        let newForm: Partial<Models.ContentState>
        if (str === undefined) {
          newForm = mutate(undefined)
        } else {
          const parts = str.split(",").map(s => s.trim()).filter(p => p !== "")
          newForm = mutate(parts)
        }
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleBooleanChange = React.useCallback((mutate: MutateFn<boolean>) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        const bool = event.target.checked
        const newForm = mutate(bool)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleInstantChange = React.useCallback((mutate: MutateFn<number>) => {
      return (dayjs: Dayjs | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => {
        const instant = dayjs === null ? undefined : dayjs.valueOf()
        const newForm = mutate(instant)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const state: ContentEditPageState = {
      isAnythingChanged     : isAnythingChanged,
      currentForm           : contentForm,
      original              : content,
      handleStringChange    : handleStringChange,
      handleStringChange2   : handleStringChange2,
      handleStringSetChange : handleStringSetChange,
      handleInstantChange   : handleInstantChange,
      handleBooleanChange   : handleBooleanChange,
    };

    React.useEffect(() => {
      setIsAnythingChanged(!isEqualIgnoreUndefined(content, contentForm));
    }, [content, contentForm, setIsAnythingChanged]);

    React.useEffect(() => {
      setContentForm(content);
    }, [content]);

    if(content === undefined) {
        return <div>Content Not Found For Id: {props.contentId}</div>
    }

    const mainComponent = (
      <Stack spacing={2} direction="row">
        <MarkdownEditorNew {...state}/>
        <ContentSettings {...state}/>
      </Stack>
    );

    return state.original.blackListedDomainWarnings && state.original.blackListedDomainWarnings.length > 0
      ? (
        <Stack spacing={2}>
          <Alert severity="warning" variant="filled">{`Article is using black listed domains: ${state.original.blackListedDomainWarnings.join(", ")}`}</Alert>
          {mainComponent}
        </Stack>
      )
      : mainComponent;
}

const MarkdownEditorNew = (props: ContentEditPageState) => {
  return React.useMemo(() => {
    return (
      <Box flexGrow={1}>
        <Paper sx={{padding: "0px"}} elevation={2}>
          <MarkdownEditor
            onChange={props.handleStringChange2(string => ({markdownString: string}))}
            initialValue={props.original.markdownString}
          />
        </Paper>
      </Box>
    );
  }, [props.handleStringChange2, props.original])
}

const ContentSettings = (props: ContentEditPageState) => (
  <Box width="512px">
    <Paper sx={{padding: "16px"}} elevation={2}>
      <Stack spacing={2}>
        <Typography variant="h2">Content settings</Typography>
        <NormalSettings {...props} />
        <AdvancedSettings {...props}/>
        <Buttons {...props}/>
      </Stack>
    </Paper>
  </Box>
);

const NormalSettings = (props: ContentEditPageState) => (
  <Stack spacing={2}>
    <Stack spacing={2} direction="row">
      <StatusIndicatorChip {...props}/>
      <TypeField {...props}/>
    </Stack>
    <Stack spacing={2} direction="row">
      <WebsiteField {...props}/>
      <CategoriesField {...props}/>
    </Stack>
    { props.original.publishTime !== undefined && <PublishTimeField {...props}/> }
    <Stack spacing={2} direction="row">
      <IsSponsoredField {...props}/>
      <IsFeaturedField {...props}/>
      <IsAiArticleField {...props}/>
    </Stack>
    <ExcerptField {...props}/>
  </Stack>
);

const AdvancedSettings = (props: ContentEditPageState) => {
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = React.useState(false);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <IconButton size="small" onClick={() => setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)}>
          {isAdvancedSettingsOpen ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
        </IconButton>
        <Typography lineHeight="36px" variant="h3">Advanced settings</Typography>
      </Stack>
      <Collapse timeout={0} in={isAdvancedSettingsOpen}>
        <Stack spacing={2}>
          <RegenerateAiDescriptionButton  {...props}/>
          <Stack spacing={2} direction="row">
            <InternalTagsField {...props}/>
            <FeaturedImageIdField {...props}/>
          </Stack>
          <Stack spacing={2} direction="row">
            <AuthorIdField {...props}/>
            <DirectoryField {...props}/>
          </Stack>
          <SlugField {...props}/>
          <MetaTitleField {...props}/>
          <MetaDescriptionField {...props}/>
          <JsonLdField {...props}/>
          <NotesField {...props}/>
        </Stack>
      </Collapse>
    </>
  );
};

const Buttons = (props: ContentEditPageState) => {

  return (
    <>
      <Stack spacing={2} direction="row">
        <UpdateButton {...props}/>
        { props.original.publishTime === undefined
          ? <PublishButton {...props}/>
          : <UnpublishButton {...props}/>
        }
        <Box flexGrow={1}/>
        { props.original.isArchived
          ? <UnarchiveButton {...props}/>
          : <ArchiveButton {...props}/>
        }
      </Stack>
    </>
  );
}

const stringCompare = (original: string | undefined, form: string | undefined) => {
  const nonEmptyOriginal = original === undefined || original.length === 0 ? undefined : original;
  const nonEmptyForm     = form === undefined || form.length === 0 ? undefined : form;

  return nonEmptyOriginal === nonEmptyForm
    ? undefined
    : nonEmptyForm;
};

const stringSetCompare = (original: ReadonlyArray<string> | undefined, form: ReadonlyArray<string> | undefined) => {
  const nonEmptyOriginal = original === undefined || original.length === 0 ? undefined : original;
  const nonEmptyForm     = form === undefined || form.length === 0 ? undefined : form;

  return nonEmptyOriginal === nonEmptyForm
    ? undefined
    : nonEmptyForm;
};

const boolCompare = (original: boolean | undefined, form: boolean | undefined) => {
  const nonEmptyOriginal = original === undefined || !original ? undefined : original;
  const nonEmptyForm     = form === undefined || !form ? undefined : form;

  return nonEmptyOriginal === nonEmptyForm
    ? undefined
    : (nonEmptyForm || false);
};

const UpdateButton = (props: ContentEditPageState) => {
  const {showError, showSuccess} = useSnackbar();
  const {updateContent} = useContentStore();
  const {original, currentForm} = props;

  const handleUpdate = () => {
    const command: UpdateContentCommand = {
      contentId: original.id,

      authorId:        stringCompare(original.authorId, currentForm.authorId),
      categories:      stringSetCompare(original.categories, currentForm.categories),
      directory:       stringCompare(original.directory, currentForm.directory),
      excerpt:         stringCompare(original.excerpt, currentForm.excerpt),
      featuredImageId: stringCompare(original.featuredImageId, currentForm.featuredImageId),
      internalTags:    stringSetCompare(original.internalTags, currentForm.internalTags),
      isFeatured:      boolCompare(original.isFeatured, currentForm.isFeatured),
      isAiArticle:     boolCompare(original.isAiArticle, currentForm.isAiArticle),
      isSponsored:     boolCompare(original.isSponsored, currentForm.isSponsored),
      jsonLd1:         stringCompare(original.jsonLd1, currentForm.jsonLd1),
      markdownString:  stringCompare(original.markdownString, currentForm.markdownString),
      metaTitle:       stringCompare(original.metaTitle, currentForm.metaTitle),
      metaDescription: stringCompare(original.metaDescription, currentForm.metaDescription),
      notes:           stringCompare(original.notes, currentForm.notes),
      slug:            stringCompare(original.slug, currentForm.slug),
      typ3:            stringCompare(original.typ3, currentForm.typ3),
      websiteName:     stringCompare(original.websiteName, currentForm.websiteName),
    };

    updateContent(command)
      .then(_ => showSuccess("Content is successfully updated"))
      .catch(_ => showError("Could not update content"));
  };

  return (
    <Button variant="contained" color="primary" disabled={!props.isAnythingChanged} onClick={handleUpdate} startIcon={<SaveIcon/>}>Update</Button>
  );
}

const PublishButton = (props: ContentEditPageState) => {
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);

  return (
    <>
      <PublishDialog contentId={props.original.id} isOpen={isPublishDialogOpen} setIsOpen={setIsPublishDialogOpen}/>
      <Button variant="contained" color="secondary" startIcon={<PublishIcon/>} onClick={() => {setIsPublishDialogOpen(true)}}>Publish</Button>
    </>
  );
}

const RegenerateAiDescriptionButton = (props: ContentEditPageState) => {
  const {createAiDescription} = useContentStore();
  const snackbar = useSnackbar();

  const command: Models.CreateAiDescriptionCommand = {
    contentId: props.original.id,
  };

  const regenerateHandler = () => {
    createAiDescription(command)
      .then(_ => {
        snackbar.showSuccess("AI description generation requested");
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`);
    })
  }

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => {regenerateHandler()}}>Regenerate AI Description</Button>
    </>
  );
}

const UnpublishButton = (props: ContentEditPageState) => {
  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = React.useState(false);

  return (
    <>
      <UnpublishDialog contentId={props.original.id} isOpen={isUnpublishDialogOpen} setIsOpen={setIsUnpublishDialogOpen}/>
      <Button variant="contained" color="secondary" startIcon={<CancelIcon/>} onClick={() => {setIsUnpublishDialogOpen(true)}}>Unpublish</Button>
    </>
  );
}

const UnarchiveButton = (props: ContentEditPageState) => {
  const [isUnarchiveDialogOpen, setIsUnarchiveDialogOpen] = React.useState(false);

  return <>
    <UnarchiveDialog contentId={props.original.id} isOpen={isUnarchiveDialogOpen} setIsOpen={setIsUnarchiveDialogOpen}/>
    <Button variant="outlined" color="error" startIcon={<UnarchiveIcon/>} onClick={() => {setIsUnarchiveDialogOpen(true)}}>Unarchive</Button>
  </>
}

const ArchiveButton = (props: ContentEditPageState) => {
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = React.useState(false);

  return <>
    <ArchiveDialog contentId={props.original.id} isOpen={isArchiveDialogOpen} setIsOpen={setIsArchiveDialogOpen}/>
    <Button variant="outlined" color="error" startIcon={<ArchiveIcon/>} onClick={() => {setIsArchiveDialogOpen(true)}}>Archive</Button>
  </>
}

const StatusIndicatorChip = (props: ContentEditPageState) => (
  <Box sx={{height: "100%", width:"100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
    { props.original.publishTime === undefined
      ? <Chip color="warning" label="Draft" sx={{width: "100%"}}/>
      : props.original.publishTime <= Date.now()
        ? <Chip color="success" label="Published" sx={{width: "100%"}}/>
        : <Chip color="info" label="Scheduled" sx={{width: "100%"}}/>
    }
  </Box>
);

const TypeField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({typ3: string}))}
    defaultValue={props.original.typ3}
    size="small"
    label="Content type"
    fullWidth
  />
);

const WebsiteField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({websiteName: string}))}
    defaultValue={props.original.websiteName}
    size="small"
    label="Website"
    fullWidth
  />
);

const PublishTimeField = (props: ContentEditPageState) => (
  <DateTimePicker
    onChange={props.handleInstantChange(instant => ({publishTime: instant}))}
    defaultValue={dayjs(props.original.publishTime)}
    slotProps={{ textField: { fullWidth: true, size: "small" } }}
    label="Publish time"
    readOnly
  />
);

const CategoriesField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringSetChange(set => ({categories: set}))}
    defaultValue={props.original.categories.join(", ")}
    size="small"
    label="Categories"
    fullWidth
  />
);

const IsAiArticleField = (props: ContentEditPageState) => (
  <FormControlLabel
    labelPlacement="start"
    control={<Checkbox
      onChange={props.handleBooleanChange(bool => ({isAiArticle: bool}))}
      defaultChecked={props.original.isAiArticle}
      size="small"
    />}
    label="Ai Article"
  />
);

const IsSponsoredField = (props: ContentEditPageState) => (
  <FormControlLabel
    labelPlacement="start"
    control={<Checkbox
      onChange={props.handleBooleanChange(bool => ({isSponsored: bool}))}
      defaultChecked={props.original.isSponsored}
      size="small"
    />}
    label="Sponsored"
  />
);

const IsFeaturedField = (props: ContentEditPageState) => (
  <FormControlLabel
    labelPlacement="start"
    control={<Checkbox
      onChange={props.handleBooleanChange(bool => ({isFeatured: bool}))}
      defaultChecked={props.original.isFeatured}
      size="small"
    />}
    label="Featured"
  />
);

const ExcerptField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({excerpt: string}))}
    defaultValue={props.original.excerpt}
    size="small"
    label="Excerpt"
    multiline
    rows={3}
    fullWidth
  />
);

const InternalTagsField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringSetChange(set => ({internalTags: set}))}
    defaultValue={props.original.internalTags?.join(", ")}
    size="small"
    label="Internal tags"
    fullWidth
  />
);

const NotesField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({notes: string}))}
    defaultValue={props.original.notes}
    size="small"
    label="Notes"
    multiline
    rows={3}
    fullWidth
  />
);

const FeaturedImageIdField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({featuredImageId: string}))}
      defaultValue={props.original.featuredImageId}
      size="small"
      label="Featured Image Id"
      fullWidth
    />);
};

const AuthorIdField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({authorId: string}))}
      defaultValue={props.original.authorId}
      size="small"
      label="Author Id"
      fullWidth
    />);
};

const DirectoryField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({directory: string}))}
      defaultValue={props.original.directory}
      size="small"
      label="Directory"
      fullWidth
    />);
};

const SlugField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({slug: string}))}
      defaultValue={props.original.slug}
      size="small"
      label="Slug"
      fullWidth
    />);
};

const MetaTitleField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({metaTitle: string}))}
      defaultValue={props.original.metaTitle}
      placeholder="Defaults to document title"
      size="small"
      label="Meta Title"
      fullWidth
    />);
};

const MetaDescriptionField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({metaDescription: string}))}
      defaultValue={props.original.metaDescription}
      size="small"
      label="Meta description"
      multiline
      rows={3}
      fullWidth
    />);
};

const JsonLdField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({jsonLd1: string}))}
      defaultValue={props.original.jsonLd1}
      size="small"
      label="Json Ld"
      multiline
      rows={3}
      fullWidth
    />);
};