import axios from "axios";
import { Article, ArticleListResponse } from "./ArticleModels";
import { HOST } from "../../Constants";
import {extractData} from "../../support/ApiUtils";

export async function listAllArticlesApi(accessToken: string | undefined, snapshotTime: number | undefined) {
  
  const response = await axios.get<ArticleListResponse>(`${HOST}/writing-tasks/list`,
    {
      params: {
        since: snapshotTime,
      },
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    }
  )

  return extractData(response)
}

export async function importKeywordSheet(accessToken: string | undefined, googleSheetsUrl: string | undefined) {

  const response = await axios.post<ArticleListResponse>(`${HOST}/writing-tasks/import`,
    { googleSheetsUrl },
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    }
  )

  return extractData(response)
}

export async function updateArticleApi(
  accessToken:   string | undefined,
  updateCommand: UpdateArticleCommand
) {
  
  const response = await axios.post<Article>(`${HOST}/writing-tasks/update`,
    updateCommand,
    { headers: {
        'Authorization': `Bearer ${accessToken}`,
    }}
  )

  return extractData(response)
}

export async function deleteArticleApi(
  accessToken:   string | undefined,
  deleteCommand: DeleteArticleCommand
) {
  
  const response = await axios.post<Article>(`${HOST}/writing-tasks/delete`,
    deleteCommand,
    { headers: {
        'Authorization': `Bearer ${accessToken}`,
    }}
  )

  return extractData(response)
}

export async function createArticleApi(
  accessToken:   string | undefined,
  createCommand: CreateArticleCommand
) {
  
  const response = await axios.post<Article>(`${HOST}/writing-tasks/create`,
    createCommand,
    { headers: {
        'Authorization': `Bearer ${accessToken}`,
    }}
  )

  return extractData(response)
}

export type UpdateArticleCommand = {
  taskId:                  string,
  websiteName?:            string,
  mainKeyword?:            string,
  categoryKeyword?:        string,
  pageKeyword?:            string,
  status?:                 string,
  writerName?:             string,
  keywordResearchUrl?:     string,
  contentEditorUrl?:       string,
  plannedPublicationDate?: string,
  comments?:               string,
  writingCostInUsd?:       string,
  publishedUrl?:           string,
}

export type CreateArticleCommand = {
  websiteName:            string,
  mainKeyword:            string,
  pageKeyword:            string,
  plannedPublicationDate: string,
  categoryKeyword:        string,
  writerName:             string,
  keywordResearchUrl:     string,
  contentEditorUrl:       string,
  comments:               string,
  writingCostInUsd:       string,
  publishedUrl:           string,
}

export type DeleteArticleCommand = {
  taskId:                  string,
}